export enum EntityType {
  FILIERA = 'filiera',
  AGRITURISMI = 'agriturismi',
  CANTINE = 'cantine',
  CONSORZIO = 'consorzio',
  EVENT = 'event',
  FRANTOI = 'frantoi',
  GAL = 'gal',
  ITINERARI = 'itinerari',
  PAGE = 'page',
  PLACE = 'place',
  PRODOTTI = 'prodotti',
  RICETTE = 'ricette',
  RISTORANTI = 'ristoranti',
  RISTORAZIONE = 'ristorazione',
  DEFAULT = 'default',
}

export type FilieraEntityType = Exclude<
  EntityType,
  | EntityType.ITINERARI
  | EntityType.EVENT
  | EntityType.PAGE
  | EntityType.PLACE
  | EntityType.PRODOTTI
  | EntityType.RICETTE
  | EntityType.GAL
  | EntityType.CONSORZIO
  | EntityType.FILIERA
  | EntityType.DEFAULT
>;
