import Image from 'next/image';
import Link from 'next/link';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import ContainerFavoriteCardButton from '../container-favorite-card-button/ContainerFavoriteCardButton';
import { CardType } from '@/models/types/types';
import Title from '@/components/core/title/Title';

interface PrimaryCardProps<T = CardType> {
  item?: Partial<T>;
  className?: string;
  preventDefault?: boolean;
  selected?: boolean;
  priority?: boolean;
  onChangeSelected?: (ref: React.RefObject<HTMLAnchorElement>) => void;
  onClick?: () => void;
}

const PrimaryCard = ({
  item,
  className = '',
  preventDefault,
  selected,
  priority = false,
  onChangeSelected,
  onClick,
}: PrimaryCardProps) => {
  const ref = useRef<HTMLAnchorElement>(null);

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      preventDefault && e.preventDefault();
      preventDefault && e.stopPropagation();
      onClick?.();
    },
    [onClick, preventDefault],
  );

  useEffect(() => {
    if (ref.current && selected && onChangeSelected) {
      onChangeSelected?.(ref);
    }
  }, [selected, onChangeSelected]);

  return (
    <Link
      ref={ref}
      href={item?.permalink ?? '/'}
      onClick={handleOnClick}
      className={clsx('group relative block overflow-hidden rounded-md shadow-md', className, {
        'h-full w-full': !className,
      })}
    >
      <Image
        blurDataURL="/placeholder.png"
        placeholder="blur"
        src={item?.image?.url ?? '/placeholder.png'}
        alt={item?.image?.caption ?? 'image' + '_calabria_terrabuona'}
        fill
        priority={priority}
        className="object-cover"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent to-60%" />

      <div
        className={clsx(
          'absolute inset-0 bottom-0 border-b-[6px] border-primary-500 transition-all duration-300',
        )}
      >
        <div className="absolute right-2 top-2 ">
          <ContainerFavoriteCardButton item={item} />
        </div>
        <div className="flex h-full w-full items-end p-4">
          <Title className="text-drop-shadow text-white" heading="h3" sizeBar="sm">
            {item?.title ?? 'Titolo'}
          </Title>
        </div>
      </div>
    </Link>
  );
};

export default memo(PrimaryCard);
