import { EntityType, FilieraEntityType } from '@/models/entity-type/EntityType';

import { ReactComponent as frantoiBoldIcon } from '@/assets/images/icons/filiera/bold/frantoi-icon.svg';
import { ReactComponent as frantoiThinIcon } from '@/assets/images/icons/filiera/thin/frantoi-icon.svg';

import { ReactComponent as agriturismiBoldIcon } from '@/assets/images/icons/filiera/bold/agriturismi-icon.svg';
import { ReactComponent as agriturismiThinIcon } from '@/assets/images/icons/filiera/thin/agriturismi-icon.svg';

import { ReactComponent as cantineBoldIcon } from '@/assets/images/icons/filiera/bold/cantine-icon.svg';
import { ReactComponent as cantineThinIcon } from '@/assets/images/icons/filiera/thin/cantine-icon.svg';

import { ReactComponent as ristorantiBoldIcon } from '@/assets/images/icons/filiera/bold/ristoranti-icon.svg';
import { ReactComponent as ristorantiThinIcon } from '@/assets/images/icons/filiera/thin/ristoranti-icon.svg';

const IconMapping: Record<
  FilieraEntityType,
  { bold: React.FC<React.SVGProps<SVGSVGElement>>; thin: React.FC<React.SVGProps<SVGSVGElement>> }
> = {
  [EntityType.FRANTOI]: {
    bold: frantoiBoldIcon,
    thin: frantoiThinIcon,
  },
  [EntityType.AGRITURISMI]: {
    bold: agriturismiBoldIcon,
    thin: agriturismiThinIcon,
  },
  [EntityType.CANTINE]: {
    bold: cantineBoldIcon,
    thin: cantineThinIcon,
  },
  [EntityType.RISTORANTI]: {
    bold: ristorantiBoldIcon,
    thin: ristorantiThinIcon,
  },
  [EntityType.RISTORAZIONE]: {
    bold: ristorantiBoldIcon,
    thin: ristorantiThinIcon,
  },
};

export default IconMapping;
